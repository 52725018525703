<template>
    <div class="ecgreport">
        <div class="head-nav" ref="headNav" :class="{'head-focus':headOpen}">
            <div class="nav-container">
                <div class="nav-item" :class="patientId?'nav-active':headOpen&&searchType==1?'nav-active open-active':'' " @click="searchEcg(1)">
                    <span>{{!patientId?$t("EcgReport.all"):patientList.find(item=>item.id == patientId).name}}</span>
                    <van-icon name="play"  />
                </div>
                <div class="nav-item" :class="emergency?'nav-active':headOpen&&searchType==2?'nav-active open-active':'' "  @click="searchEcg(2)">
                    <span>{{!emergency?$t("EcgReport.assess"):level.find(item=>item.Lv == emergency).name}}</span>
                    <van-icon name="play"  />
                </div>
                <div class="nav-item" :class="orderBy?'nav-active':headOpen&&searchType==3?'nav-active open-active':'' " @click="searchEcg(3)">
                    <span>{{!orderBy?$t("EcgReport.sort"):orderBy=='knx.request_time asc'?$t("EcgReport.ascend"):$t("EcgReport.Descend")}}</span>
                    <van-icon name="play"  />
                </div>
            </div>
            <div class="head-condition" v-show="headOpen">
                <template v-if="searchType == 1">
                    <p class="condition-item" :class="{'condition-active':patientId==null}" @click="searchSet(1,null)">
                        {{$t("EcgReport.all")}}
                    </p>
                    <p class="condition-item" :class="{'condition-active':patientId==item.id}" v-for="(item,index) in patientList" :key="index" @click="searchSet(1,item.id)">
                        {{item.name}}
                    </p>
                </template>
                <template v-if="searchType == 2">
                    <p class="condition-item" :class="{'condition-active':emergency==item.Lv}" v-for="(item,index) in level" :key="index" @click="searchSet(2,item.Lv)">
                        {{item.name}}
                    </p>
                </template>
                <template v-if="searchType == 3">
                    <p class="condition-item" :class="{'condition-active':!orderBy}"  @click="searchSet(3,'')">
                        {{$t("EcgReport.defaultSort")}}
                    </p>
                    <p class="condition-item" :class="{'condition-active':orderBy=='knx.request_time asc'}"  @click="searchSet(3,'knx.request_time asc')">
                        {{$t("EcgReport.ascend")}}
                    </p>
                    <p class="condition-item" :class="{'condition-active':orderBy=='knx.request_time desc'}"  @click="searchSet(3,'knx.request_time desc')">
                        {{$t("EcgReport.Descend")}}
                    </p>
                </template>
                <p class="closebtn" @click="headOpen = false">关闭</p>
            </div>
        </div>
        <div class="ecgreport-list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :class="{'refresh-null':EcgList.length==0}">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                    <div class="ecgreport-item" @click="goDetails(item)" v-for="(item,index) in EcgList" :key="index">
                        <p class="title">
                            <span>{{item.patientName}}</span>
                            <span>{{item.patientTel}}</span>
                        </p>
                        <div class="ecg-info">
                            <div class="info-left">
                                <p class="mechanism">签发机构：{{item.agency}}</p>
                                <div class="ecg-time">
                                    <i class="line"></i>
                                    <p class="time-txt">上传：{{item.requestTime}}</p>
                                    <p class="time-txt">审核：{{item.ecgReportTime}}</p>
                                </div>
                            </div>
                            <img src="@/assets/img/ecg-putong-icon.png" v-if="item.emergency == 1" class="img-right" alt="">
                            <img src="@/assets/img/ecg-yujin-icon.png" v-if="item.emergency == 2" class="img-right" alt="">
                            <img src="@/assets/img/ecg-weiji-icon.png" v-if="item.emergency == 3" class="img-right" alt="">
                            <img src="@/assets/img/ecg-wufa-icon.png" v-if="item.emergency == 4" class="img-right" alt="">
                        </div>
                        <div class="diagnosis">
                            <p class="txt">
                                {{item.diagnosis}}
                            </p>
                            <i class="icons"></i>
                        </div>
                    </div>
                    <div class="nodata" v-if="EcgList.length==0">
                        <img src="@/assets/img/data-null.png" >
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import { patientQuery  } from "@/api/patient";
import { ecgReportQuery } from "@/api/EcgReport"
export default {
    data () {
        return {
            patientList:[],
            patientId:null,
            searchType:null,
            headOpen:false,
            emergency:'',
            pageNum:1,
            pageSize:10,
            orderBy:"",
            EcgList: [],
            loading: false,
            finished: false,
            refreshing: false,
            level:[
                {
                    name:"全部等级",
                    Lv:''
                },{
                    name:"危急",
                    Lv:3
                },{
                    name:"预警",
                    Lv:2
                },{
                    name:"普通",
                    Lv:1
                },{
                    name:"无法评估",
                    Lv:4
                }
            ]
        }
    },
    created () {
        this.getPatient()
        document.title = this.$t("EcgReport.name");
    },
    methods: {
        // 获取就诊人
        getPatient(){
            patientQuery({}).then(res =>{
                if(res.data.errorCode == 0){
                    this.patientList = res.data.data;
                }
            })
        },
        searchEcg(type){
            
            this.searchType = type;
            this.headOpen = true;
        },
        searchSet(type,value){
            if(type == 1){
                this.patientId = value;
            }else if(type == 2){
                this.emergency = value;
            }else if(type == 3){
                this.orderBy = value;
            }
            this.pageNum = 1;
            this.headOpen = false;
            this.onLoad();
        },
        goDetails(item){
            this.$router.push({
                path:"/EcgReport/details",
                query:{
                    id:item.id
                }
            })    
        },
        // 获取列表数据
        onLoad() {
            let datadd = {
                patientId:this.patientId,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                emergency:this.emergency,
                orderBy:this.orderBy
            }
            ecgReportQuery(datadd).then(res =>{
                this.loading = false;
                if(res.data.errorCode == 0){
                    if (this.refreshing) {
                        this.refreshing = false;
                    }
                    if(this.pageNum == 1){
                        this.EcgList = res.data.data.data;
                    }else{
                        for(let i in res.data.data.data){
                            let item = res.data.data.data[i];
                            this.EcgList.push(item)
                        }
                    }
                    if(res.data.data.total <= this.EcgList.length ){
                        this.finished = true;
                    }
                    this.pageNum ++;
                }else{
                    if(this.pageNum != 1){
                        this.finished = true;
                    }
                }
            })
            // setTimeout(() => {
            //     if (this.refreshing) {
            //         this.list = [];
            //         this.refreshing = false;
            //         }

            //         for (let i = 0; i < 10; i++) {
            //         this.list.push(this.list.length + 1);
            //         }
            //         this.loading = false;

            //         if (this.list.length >= 40) {
            //         this.finished = true;
            //     }
            // }, 1000);
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.pageNum = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
    }
}
</script>

<style lang="less" scoped>
.nodata{
    height: calc(100vh - 1.6rem);
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 8rem;
    }
}
.ecgreport{
    height: 100%;
    padding-top: 1.6rem;
    position: relative;
    width: 100%;
    overflow: auto;
    background: #f5f5f5;
    box-sizing: border-box;
    .head-nav{
        height: 1.6rem;
        position: absolute;
        z-index: 9;
        width: 100%;
        top: 0;
        left: 0;
        background: #fff;
        transition: all .3s linear 0s;
        .nav-container{
            flex: 0 0 auto;
            box-sizing: border-box;
            padding: 0 0.6rem;
            height: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .van-icon {
                transform: rotate(90deg);
                color: #D8D8D8;
                margin-left: 0.1rem;
            }
            .nav-item{
                font-size: 0.56rem;
                display: flex;
                align-items: center;
                // flex: 1;
            }
            .nav-active{
                color: #409EFF;
                .van-icon {
                    color: #409EFF;
                }
            }
            .open-active{
                .van-icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }
    .head-focus{
        height: 100vh;
        display: flex;
        flex-direction: column;
        .head-condition{
            flex: 1;
            overflow: auto;
            padding: 0 0.3rem;
            padding-bottom: 2rem;
            position: relative;
            .condition-item{
                padding: 0 0.3rem;
                height: 1.2rem;
                font-size: 0.6rem;
                line-height: 1.2rem;
                font-weight: 500;
                margin-bottom: 0.2rem;
            }
            .condition-active{
                border-radius: 0.1rem;
                color: #409EFF;
                background: #dbebfe;
            }
            .closebtn{
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2rem;
                line-height: 2rem;
                font-size: 0.6rem;
                width: 100%;
                text-align: center;
            }
        }
    }
    .ecgreport-list{
        height: 100%;
        padding: 0 0.6rem;
        overflow: auto;
        /deep/ .van-pull-refresh{
            .van-loading__text{
                font-size: 0.56rem;
            }
        }
        .refresh-null{
            height: 100%;
        }
        .ecgreport-item{
            padding:0  0.4rem;
            background: #fff;
            border-radius: 0.2rem;
            overflow: auto;
            margin-top: 0.4rem;
            .title{
                height: 1.6rem;
                line-height: 1.6rem;
                border-bottom: 0.02rem solid #D8D8D8;
                font-size: 0.6rem;
                font-weight: 600;
                span{
                    &:first-child{
                        margin-right: 0.4rem;
                    }
                }
            }
            .ecg-info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.4rem 0 0.2rem 0;
                .info-left{
                    .mechanism{
                        font-size: 0.6rem;
                        color: #333333;
                        line-height: 0.84rem;
                    }
                    .ecg-time{
                        padding-left: 1rem;
                        margin: 0.3rem 0;
                        position: relative;
                        .line{
                            height: 50%;
                            width: 0.06rem;
                            background:#D8D8D8;
                            position: absolute;
                            left: 0.25rem;
                            top: 50%;
                            transform: translate(0, -50%);
                        }
                        .time-txt{
                            font-size: 0.52rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #989898;
                            position: relative;
                            &:last-child{
                                margin-top: 0.2rem;
                            }
                            &::after{
                                position: absolute;
                                content: ' ';
                                left: -0.8rem;
                                top: 50%;
                                width: 0.2rem;
                                height: .2rem;
                                background: #D8D8D8;
                                transform: translate(0, -50%);
                                border-radius: 50%;
                            }
                        }
                    }
                }
                .img-right{
                    width: 2.8rem;
                    height: 2.76rem;
                }
            }
            .diagnosis{
                padding: 0.6rem 0.5rem;
                background: #F2F8FF;
                border-radius: 0.1rem;
                border: 0.02rem dashed #ABB7F2;
                margin-bottom: 0.6rem;
                display: flex;
                align-items: center;
                .txt{
                    flex: 1;
                    font-size: 0.64rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    word-break:break-all;
                    display:-webkit-box;
                    -webkit-line-clamp:2;
                    -webkit-box-orient:vertical;
                    overflow:hidden;
                }
                .icons{
                    width: 1rem;
                    height: 1rem;
                    margin:0  0.3rem 0 0.8rem;
                    background: url("~@/assets/img/detail-more.png") no-repeat;
                    background-size: 100%;
                }
            }
        }
        // background: red;
    }
}
</style>