import request from '../utils/request'

//心电报告列表 列表
export function ecgReportQuery(data) {
    return request({
        url: "/kangnaixin/query",
        method: "post",
        data
    })
}

export function ecgReportGet(data) {
    return request({
        url: "/kangnaixin/get",
        method: "post",
        data
    })
}
